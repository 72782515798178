<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	// import Gen from '../helper/Gen';
	import Popper from 'vue-popperjs';
	import 'vue-popperjs/dist/vue-popper.css';

	export default {
		extends: BaseVue,
		data() {
			return {
				input:{},
				image_preview:"",
			}
		},
		computed:{
			mrValidation(){
				return this.$root.config.mrValidation.account_setting
			},
			uemail(){
				return this.user && Gen.userToken() ? this.user.email : '-'
			},
			ufullname(){
				return this.user && Gen.userToken() ? this.user.fullname : '-'
			},
			upic(){
				return this.user && Gen.userToken() ? this.user.pic : '-'
			},
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (this.$route.name) {
				setTimeout(() => {
					SEMICOLON.widget.carousel();
					$('[data-toggle="popover"]').popover()
				}, 300)
			}

			if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
			setTimeout(() => {
				SEMICOLON.documentOnLoad.init()
				SEMICOLON.widget.carousel();
				SEMICOLON.documentOnResize.init()
				$('[data-toggle="popover"]').popover()
			}, 200)
			$(document).ready(() => {
				AOS.init()
			})
			this.getData()
		},
		methods: {
			onSubmit(){
				Gen.apirest("/submit-account-setting",this.input, (err,resp)=>{
					if(err) return Gen.info(err.resp.message, 'warning')
					this.getData()
					this.refreshVariableFo()
					Gen.info(resp.message, 'success')
				},"POST")
			},
			getData(){
				Gen.apirest("/get-account-setting",{}, (err,resp)=>{
					if(err) return console.log(err)
					this.$set(this, 'input', resp.data)
					setTimeout(() => { this.image_preview = "" }, 10);
				})
			},
		},
		watch:{
			'input.pic'(){
				this.image_preview = this.input.pic
			}
		}
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row">
						<div class="col-md-8 col-12">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><router-link :to="{name:'Dashboard'}">{{web.mn_dashboard}}</router-link></li>
								<li class="breadcrumb-item active" aria-current="page">{{web.mn_account_setting}}</li>
							</ol>
						</div>
					</div>
					<div class="row justify-content-around dark">
						<div class="col-md-4">
							<div class="box_profile">
								<div class="ava_wrap">
									<img v-if="!upic" :src="assets('fo_images','wine_ava.png')" :alt="ufullname" :title="ufullname" />
									<img class="rounded-circle" v-else :src="uploader(upic)" :alt="ufullname" :title="ufullname" />
								</div>
								<h3>{{ufullname}}</h3>
								<p>{{uemail}}</p>
							</div>
						</div>
						<div class="col-md-8">
							<div class="wrap_login">
								<h4 class="text-left">{{web.dsh_edit_your_profile}}.</h4>
								<div class="info"></div>
								<div class="row">
									<div class="col-md-10 mb-2">
										<VForm @resp="onSubmit">
											<div class="form-group row mb-5">
												<label for="staticEmail" class="col-sm-3 col-form-label">{{web.dsh_foto_profile}}</label>
												<div class="col-sm-4">
													<img v-if="image_preview" class="rounded-circle mb-2" :src="uploader(image_preview)" :alt="ufullname" :title="ufullname" />
													<Uploader name="bu_pic" noPreview noHelp type="fo_user" isFrontend uploadType="cropping" :param="{thumbnail:true}" v-model="input.pic"></Uploader>
												</div>
											</div>
											<div class="form-group row">
												<label for="staticEmail" class="col-sm-3 col-form-label">{{web.dsh_name}}</label>
												<div class="col-sm-9">
													<input type="text" v-model="input.fullname" name="fullname" onkeydown="return fullNameKey(event)" class="frm_custom_sign" v-bind="validation('fullname')" placeholder="Full Name">
													<LabelError name="fullname"></LabelError>
												</div>
											</div>
											<div class="form-group row">
												<label for="inputPassword" class="col-sm-3 col-form-label">{{web.lbl_no_handphone}}</label>
												<div class="col-sm-9">
													<input type="text" v-model="input.phone" name="phone" onkeydown="return phoneKey(event)"  v-bind="validation('phone')" class="frm_custom_sign" placeholder="No. Handphone">
													<LabelError name="phone"></LabelError>
												</div>
											</div>
											<div class="form-group row">
												<label for="inputPassword" class="col-sm-3 col-form-label">{{web.dsh_email_address}}</label>
												<div class="col-sm-9">
													<input type="text" readonly name="email" onkeydown="return emailKey(event)"  v-bind="validation('email')" class="frm_custom_sign" :value="uemail" placeholder="Email Address">
													<LabelError name="email"></LabelError>
												</div>
											</div>
											<div class="form-group row">
												<label for="inputPassword" class="col-sm-3 col-form-label">{{web.dsh_password}}</label>
												<div class="col-sm-9 mb-4">
													<a data-toggle="collapse" href="#ChangePassword" role="button" aria-expanded="false"
														aria-controls="ChangePassword" class="btn_change_password"><i
															class="icon-line2-lock-open"></i> {{web.btn_change}} </a>
												</div>
											</div>
											<div class="row collapse" id="ChangePassword">
												<div class="col-md-12">
													<div class="form-group row">
														<label for="inputPassword" class="col-sm-3 col-form-label">{{web.dsh_new_password}}</label>
														<div class="col-sm-9">
															<input type="password" v-model="input.password" name="password" id="password" v-bind="validation('password')" class="frm_custom_sign" placeholder="New Password">
															<LabelError name="password"></LabelError>
														</div>
													</div>
													<div class="form-group row">
														<label for="inputPassword" class="col-sm-3 col-form-label">{{web.btn_confirm_password}}</label>
														<div class="col-sm-9">
															<input type="password"  v-model="input.confirm_password" name="confirm_password" v-bind="validation('confirm_password')" class="frm_custom_sign" placeholder="Confirm  Password">
															<LabelError name="confirm_password"></LabelError>
														</div>
													</div>
												</div>
											</div>
										<div class="row">
											<div class="col-md-12 mt-2 text-right">
												<p><button type="submit" class="btn_setting">{{web.btn_submit}} <i class="icon-line-arrow-right"></i></button></p>
											</div>
										</div>
										</VForm>
									</div>
								</div>
							</div>
						</div>
						<div class="clear"></div>
					</div>
				</div>
			</div>
		</div>
	</section><!-- #content end -->
</template>